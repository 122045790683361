import React from "react";
import HeroSection from "../components/block-explorer/HeroSection";
import HubAndStation from "../components/block-explorer/HubAndStation";
import Layout from "../components/layout";
import CommunitySection from "../components/common/CommunitySection";
import { useBannerWidthContext } from "../context/BannerWidthContext";
import { useBannerDimensions } from "../hooks/useBannerDimensions";

function BlockExplorer() {
  const { dispatch } = useBannerWidthContext();
  const bannerRef = useBannerDimensions(dispatch);
  return (
    <div>
      <Layout headerClass="relative">
        <div className="min-h-screen">
          <div className="overflow-hidden">
            <main>
              <div ref={bannerRef} className="lg:h-[557px]">
                <HeroSection />
              </div>
              <HubAndStation />
              {/* <CommunitySection
                classes="mt-0 px-0 md:mt-[102px]"
                titleSectionClasses="px-6"
                cardSectionClasses="px-0 !mx-0 md:!mx-0"
                buttonSectionClasses="ml-6 md:ml-24 lg:ml-[102px]"
              /> */}
            </main>
          </div>
        </div>
      </Layout>
    </div>
  );
}
export default BlockExplorer;
