import React from "react";
import fetchHub_banner from "../../images/fetchHub_banner.svg";
import HubPhone from "../../images/Hub_phone.svg";
import stationPhone from "../../images/station_phone.svg";
import PrimaryButton from "../atoms/PrimaryButton";

import fetchStation_banner from "../../images/fetchStation_banner.svg";

const HubAndStation = () => {
  return (
    <section className=" px-[19.5px] md:px-0  md:-mt-20  pb-[50px] md:pb-[100px]">
      <div className="z-10 relative bg-white mx-auto rounded-[20px] flex flex-col md:flex-row ">
        <div className="md:w-1/2 absolute md:relative">
          <img
            src={fetchHub_banner}
            className="w-full max-h-[441px] md:max-h-[536px] rounded-[20px] object-cover md:block hidden"
            alt="mission 3"
          />
          <img
            src={HubPhone}
            className="md:hidden w-full mx-auto -mt-32"
            alt="mission 3"
          />
        </div>
        <div className="md:w-1/2 flex flex-col justify-center p-6 z-10 relative mt-48 md:mt-0">
          <h4 className="font-Lexend text-[36px] font-semibold leading-12 text-left tracking-normal mb-4">
            Fetch.ai Hub
          </h4>
          <div className="text-left mb-6">
            <p className="text-[16px]">
              At the Fetch.ai Foundation, we envision a world where technology
              seamlessly integrates into every aspect of industry and
              enterprise, unlocking limitless potential for growth and
              innovation.
            </p>
            <br />
            <p className="font-bold text-[16px]">
              Our aim is to revolutionize the way we do business by harnessing
              the collective power of cutting-edge AI, Web3, and multi-agent
              systems technology.
            </p>
            <PrimaryButton
              classes="md:mr-[28px] mx-auto mb-4 md:mb-0 w-full md:w-auto mt-[30px]"
              label="Go to Fetch.ai Hub"
            />
          </div>
        </div>
      </div>

      <div className="z-10 relative bg-white mx-auto  flex flex-col md:flex-row md:mt-[30px] mt-32  mb-[1100px] md:mb-0">
        <div className="md:w-1/2 mt-80 md:mt-0 absolute md:relative  md:pt-0 bg-white pl-[16px] rounded-[20px] pb-[40px] pr-[21px] md:pr-0 md:pl-0 md:pb-0 md:mx-0 flex items-center justify-center">
          <div className="z-10  md:mr-2.5 text-center md:text-start md:-mt-0.5 md:ml-16  ">
            <h4 className="font-Lexend md:text-[36px] text-[32px] font-semibold leading-12 text-left tracking-normal mb-4">
              Fetch.ai Station
            </h4>
            <div className="mt-4 text-left md:mr-6 mb-6 text-[16px] ">
              <p className="">
                Our mission is to drive the advancement of AI and Web3
                technologies, with a focus on creating a collaborative ecosystem
                for industry participants that is poised to unlock new
                innovations and business opportunities.
              </p>
              <br />
              <p className=" ">
                As a transparent governing body, initiated by Fetch.ai and
                Bosch, we bring key industry participants together, advance
                research and adoption in Web3 technology, cryptography, and
                artificial intelligence, and advocate for the shared benefits of
                these technologies.
              </p>
              <PrimaryButton
                classes="md:mr-[28px] mx-auto mb-4 md:mb-0 w-full md:w-auto mt-[30px]"
                label="Go to Fetch.ai Station"
              />
            </div>
          </div>
        </div>
        <div className="md:w-1/2 absolute md:relative flex items-center justify-center">
          <img
            src={fetchStation_banner}
            className="w-full max-h-[441px] md:max-h-[536px] rounded-[20px] object-cover md:block hidden"
            alt="mission 3"
          />
          <img
            src={stationPhone}
            className="md:hidden w-full mx-auto"
            alt="mission 3"
          />
        </div>
      </div>
    </section>
  );
};

export default HubAndStation;
