import React from "react";
import Container from "../common/Container";

export default function HeroSection() {
  return (
    <Container
      sm
      classes="lg:overflow-hidden pt-10 md:pb-15 md:pt-[129px] pb-[173px] "
    >
      <div className="mx-auto w-full ">
        <div className=" lg:gap-0 w-full mx-auto">
          <div className="lg:flex lg:items-center">
            <div className="md:pb-3 lg:-mr-4">
              <h1 className="text-[40px] leading-[48px] font-semibold text-[#0D283E] md:text-[56px] md:leading-[67px]  flex items-start md:items-center flex-col md:flex-row">
                <span className=" inline-block">Block&nbsp;</span>
                <span className="text-[42px] md:text-[56px] bg-clip-text text-transparent  bg-gradient-to-r from-[#4789CD] via-[#28D4F5] to-[#59D99D] inline-block pt-1.5 md:pt-0">
                  Explorers
                </span>
              </h1>
              <div className="text-[#0D283E]">
                <h2 className=" font-normal pt-4 pb-10  text-2xl text-[22px] ">
                  Building the autonomy for the digital economy
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
